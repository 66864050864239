angular.module('MyHippoProducer.Controllers').directive('startEndorsementSection', function () {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: 'pages/policy-details/sections/start-endorsement/start-endorsement.html',
        controller: function ($scope, $log, $state, $stateParams, PolicyService, EndorsementService, toaster) {
            $scope.hasActiveTransaction = PolicyService.hasActiveTransaction;
            if ($scope.hasActiveTransaction) {
                const lastModifiedDate = PolicyService.transactionLastModified;
                $scope.transactionLastModified = moment(lastModifiedDate).format('MM/DD/YYYY');
            }
            $scope.setEditMode = () => {
                $state.go('portal.policyDetailsV2', _.assign({}, $stateParams, { mode: 'edit' }));
            };

            $scope.setViewMode = () => {
                $state.go('portal.policyDetailsV2', _.assign({}, $stateParams, { mode: 'view' }), {reload: true});
            };

            $scope.startEndorsement = () => EndorsementService.startEndorsement()
                .then(() => $scope.setEditMode())
                .catch((err) => {
                    $log.info('Not starting endorsement', err);
                    const errorMessage = err.data || 'Unable to start endorsement';
                    toaster.pop('error', 'Error', errorMessage);
                });

            $scope.bindEndorsement = () => EndorsementService.bindEndorsement().then(() => $scope.setViewMode());
        }
    };
});
